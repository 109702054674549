/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */

import Education from "../Education/Education";
import Skills from "../Skills/Skills";
import Projects from "../Projects/Projects";
import About from "../About/About";
import Contact from "../Contact/Contact";
import WorkExperience from "../WorkExperience/WorkExperience";
import Footer from "../Footer/Footer";

export default function Home() {
  return (
    <>
      <About />
      <Education />
      <Skills />
      <Projects />
      <WorkExperience />
      <Contact />
      <Footer />
    </>
  );
}
