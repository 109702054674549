/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import styles from "./WorkExperience.module.css";
import voda from "../Resources/Images/Voda.png";
import freelance from "../Resources/Images/Freelance.png";

export default function WorkExperience() {
  // const workExperienceCardDetails = [
  //   {
  //     Company: "Freelancer",
  //     Discription: "“ A FrontEnd developer Working as a freelancer with several offline and online clients. ”",
  //     Period: "Form Oct. 2022 - Mar. 2023",
  //     CompanyLogo: freelance,
  //   },
  //     {
  //       Company: "_VOIS",
  //       Discription: "“ Software Engineering - Discover Associate program Provided by Vodafone (_VOIS) at TSSE department. ”",
  //       Period: "Form Mar. 2023 - Present",
  //       CompanyLogo: voda,
  //     },
  //     // Add more education details as needed
  //   ];

  return (
    <section id="WorkExperience" className={`${styles.bg1}`}>
    <div className={`${styles.section} container py-5`}>
      <div className="row w-100 m-auto g-3">
        <strong
          className={`text-center text-white fs-1 bordHover border-bottom border-bottom-2 rounded-4 ${styles.bg1} mb-5`}
        >
          My Work Experience
        </strong>
{/* <div className='d-lg-flex justify-content-center'>
{workExperienceCardDetails.map((WECD, index) => (
        <div key={index} className="col-lg-5 mt-5 px-3 bg-transparent border bord rounded-3">


        <div className={`card position-relative ${styles.cardBg}`}>
          <img
              className={`card-img-top ${styles.imgStyle}`}
              src={WECD.CompanyLogo}
              alt="Card image cap"
            />
        </div>

        <div className="card-body mt-2">
              <h5
                className={`card-title text-start ${styles.cardTitels} textColor mb-3`}
              >
                {WECD.Company}
              </h5>
              <p className="card-text">
              {WECD.Discription}
              </p>
              <p className="card-text mb-2">
                <small className="text-muted textColorHov">
                {WECD.Period}
                </small>
              </p>
            </div>
      </div>
))}
</div> */}


        {/* ------------------------------------Freelance Card--------------------------- */}
        <div className="col-lg-6 my-5">
          <div className={`card position-relative ${styles.cardBg} bord`}>
            <img
              className={`card-img-top ${styles.imgStyle}`}
              src={`${freelance}`}
              alt="Card image cap"
            />
            <div className="card-body">
              <h5
                className={`card-title text-start ${styles.cardTitels} textColor`}
              >
                Freelancer
              </h5>
              <p className="card-text">
                A FrontEnd developer Working as a freelancer with several
                offline and online clients.
              </p>
              <p className="card-text">
                <small className="text-muted textColorHov">
                  Form Oct. 2022 - Mar. 2023
                </small>
              </p>
            </div>
          </div>
        </div>
        {/* ------------------------------------Vois Card--------------------------- */}
        <div className="col-lg-6 my-5">
          <div className={`card position-relative ${styles.cardBg} bord`}>
            <img
              className={`card-img-top ${styles.imgStyle}`}
              src={`${voda}`}
              alt="Card image cap"
            />
            <div className="card-body">
              <h5
                className={`card-title text-start ${styles.cardTitels} textColor`}
              >
                _VOIS
              </h5>
              <p className="card-text">
                Software Engineering - Discover Associate program Provided
                by Vodafone (_VOIS) at TSSE department.
              </p>
              <p className="card-text">
                <small className="text-muted textColorHov">
                  Form Mar. 2023 - Present
                </small>
              </p>
            </div>
          </div>
        </div>

        {/* Main Row Close */}
      </div>
      {/* Container Close */}
    </div>
  </section>
)
}
