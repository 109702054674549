import React from "react";
import Pages from "../../Pages/Pages";

export default function App() {
  return (
    <>
      <Pages />
    </>
  );
}
