import React, { useRef, useState } from "react";
import styles from "./Contact.module.css";
import emailjs from "@emailjs/browser";
import { Icon } from '@iconify/react';

export default function Contact() {
    const [messSuccess, setMessSuccess] = useState("");
    const [messError, setMessError] = useState("");
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      var mailformat = /((com)|(net))/;
      if (e.target[1].value.match(mailformat)) {
        emailjs
          .sendForm(
            "service_wwy54og",
            "template_3n3f4eg",
            form.current,
            "gbPu-oAhojEWbDOpo"
          )
          .then(
            (result) => {
              setMessSuccess("Your Message sent successfuly");
              e.target.reset();
            },
            (error) => {
              setMessError("Oops! Something went wrong. Please try again later.");
            }
          );
      } else {
        setMessError("Invalid, Please Check your Email");
      }
    };
  return (
    <section id="Contact" className={`${styles.bg2}`}>
    <div className="container py-5">
      <div className="row">
        <strong
          className={`text-center text-white fs-1 bordHover border-bottom border-bottom-2 rounded-4 ${styles.bg2} mb-3`}
        >
          Contact Me
        </strong>

        {/* -----------------------------------Left side get In touch------------------------------------- */}
        <div className={`${styles.GetTouchSection} col-md-6 mt-5`}>
          <h4 className='textColor mb-4'>Get in Touch</h4>
          <p>
            Hey, hello I am
            <span className="textColor fs-5"> Mohammed Nabil </span> I
            would like to welcome you to my website. You are now on the
            contact page. I am pleased to contact you. Here you will find
            all my accounts on all social networking sites, my phone number
            and my email and thanks for getting in touch.
          </p>
          <div className="my-contact ms-4">
            <div className="d-flex align-items-center mb-3">
              <i
                className={`fa-solid fa-envelope fs-5 me-2 ${styles.envelope}`}
              ></i>
              <a
                className="text-center fs-6 mailto"
                href="mailto:mohammednabil1910@gmail.com"
              >
                mohammednabil1910@gmail.com
              </a>
            </div>
            <div className="d-flex align-items-center mb-3 my-2">
              <i
                className={`fa-solid fa-location-dot fs-5 me-2 ${styles.location}`}
              ></i>
              <span className="text-center fs-6">
                El-Shorouk City 2, Cairo
              </span>
            </div>
            <div className="d-flex align-items-center">
              <i
                className={`fa-solid fa-phone fs-5 me-2 ${styles.Phone}`}
              ></i>
              <span className="text-center fs-6">01090594627</span>
            </div>
          </div>
          <div className="row ms-auto">
            <div className="SOCIAL mt-3 fs-2 flex-md-nowrap">
              <a
                href="https://www.facebook.com/modi.nabil.52"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`fa-brands ${styles.facebook} fa-facebook mx-3 text-white`}
                ></i>
              </a>
              <a
                href="https://github.com/MuhammeedNabil?tab=repositories"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`fa-brands ${styles.github} fa-github text-white`}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/muhammed_nabil_pop/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`text-white fa-brands ${styles.instagram} fa-instagram mx-3 text-dark`}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/in/m-nabilbkr/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`fa-brands ${styles.linkedin} fa-linkedin text-white  me-3`}
                ></i>
              </a>
              <a
                href="https://twitter.com/MohammeedNabil"
                target={"_blank"}
                rel="noreferrer"
              >
                <i>
                <Icon icon="fa6-brands:x-twitter" className={`text-white mb-2 ${styles.twitter} text-dark`} />
                </i>
              </a>
            </div>
          </div>
        </div>
        {/* -----------------------------------Right side contact me------------------------------------- */}
        <div className="col-md-6 mt-5">
          <h4 className="textColor mb-4">Send Me Your Message</h4>
          <form
            ref={form}
            onSubmit={sendEmail}
            className={`bordHover textColorHov border-bottom p-3 rounded-5 ${styles.bg1}`}
          >
            <label className="mb-1">Name</label>
            <input
              className="form-control bord bg-transparent text-white"
              type="text"
              name="user_name"
              placeholder="Your Name"
              required
            />
            <label className="mb-1 mt-2">Email</label>
            <input
              className="form-control bord bg-transparent text-white mb-2"
              type="email"
              name="user_email"
              placeholder="Your Email"
              required
            />
            <label className="mb-1">Subject</label>
            <input
              className="form-control bord bg-transparent text-white mb-2"
              type="text"
              name="email_subject"
              placeholder="Email Subject"
              required
            />
            <label className="mb-1">Message</label>
            <textarea
              className="form-control bord bg-transparent text-white"
              name="message"
              placeholder="Your message"
              required
            />
            <div className=" text-center pt-3 text-white">
              {" "}
              {messSuccess ? (
                <div className=" text-success rounded-4">{messSuccess}</div>
              ) : (
                <div className="rounded-4  text-danger">{messError}</div>
              )}
            </div>
            <input
              className={`bord mt-3 bg-transparent rounded-pill px-5 py-1 bgHover textColor`}
              type="submit"
              value="Send"
            />
          </form>
        </div>
      </div>
    </div>
  </section>
  )
}
