/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styles from "./Projects.module.css";
import partyEvent from "../Resources/Videos/Party Evennt Website.mp4";
import moviesHub from "../Resources/Videos/Movie Hub.mp4";
import smartLogin from "../Resources/Videos/Smart Login.mp4";
import lampApp from "../Resources/Videos/Lamp Application.mp4";
import bookMarker from "../Resources/Videos/Book Marker.mp4";
import randomQuotes from "../Resources/Videos/Random Quotes.mp4";

export default function Projects() {
  return (
    <section id="Projects" className={`${styles.bg2}`}>
    <div className="container py-5">
      <div className="row w-100 m-auto g-3">
        <strong
          className={`text-center bordHover text-white fs-1 border-bottom border-bottom-2 rounded-4 ${styles.bg2} mb-3`}
        >
          My Projects
        </strong>
        {/*----------------------------------Party Event Website Card----------------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder text-center text-white">
              Party Event Website
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={partyEvent} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    A count down App for a party using “HTML,CSS & Js”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}

                  <div className="row g-2">
                    <a
                      href="https://muhammeednabil.github.io/Party-Event/"
                      target={"_blank"}
                    >
                      <button
                        className={`rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Party-Event"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on Mar 25
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----------------------------------Movies Hub Website Card----------------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder  text-white text-center">
              Noxe Movies Hub Website
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={moviesHub} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    Noxe Movies Hub for trending movies using “React, JS,
                    HTML,CSS, and API's”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}
                  <div className="row g-2">
                    <a href="https://noxehub.web.app/" target={"_blank"}>
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Noxe-website"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on Jun 25
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*-------------------------------turn lamp on & off Website Card-----------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder  text-white text-center">
              Turn Lamp on & off Applicaton
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={lampApp} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    A small Application making the lamp turned On or Off
                    using “HTML,CSS & Js”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}
                  <div className="row g-2">
                    <a
                      href="https://muhammeednabil.github.io/Turn-Light-On-Off/"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Turn-Light-On-Off"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on Jun 11
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----------------------------------Book Marker Website Card----------------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder  text-white text-center">
              Book Marker Application
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={bookMarker} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    Small Book Marker App. Where you can save your important
                    website links to make it easier to reach using “HTML,
                    CSS, Js”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}
                  <div className="row g-2">
                    <a
                      href="https://muhammeednabil.github.io/Book-Marker/"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Book-Marker"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on May 31
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----------------------------------Random qoutes Website Card----------------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder  text-white text-center">
              Random Quotes Application
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={randomQuotes} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    Small App. get a random quotes for each click on the
                    button using “HTML,CSS, API's & Js”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}
                  <div className="row g-2">
                    <a
                      href="https://muhammeednabil.github.io/Random-Quotes/"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Random-Quotes"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on May 31
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----------------------------------Smart-Login Website Card----------------------------------------*/}
        <div className="col-md-12 col-lg-6">
          <div className={`card mb-3 p-1 pt-2 h-100 ${styles.cardBg}`}>
            <h5 className="card-title text-dark fw-bolder  text-white text-center">
              Smart Login Application
            </h5>
            <div className="row g-0">
              <div className="col-md-7 d-flex align-items-center px-1 py-2">
                <video controls className="w-100  text-white">
                  <source src={smartLogin} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              </div>
              <div className="col-md-5 text-dark">
                <div className="card-body">
                  <p className="card-text text-white">
                    Small App. for Smart Login that prevent the unlogin
                    users to get home page using “HTML,CSS, API's & Js”
                  </p>
                  {/*---------------------------------Buttons of visiting the website----------------------------------*/}
                  <div className="row g-2">
                    <a
                      href="https://muhammeednabil.github.io/Login-Application/"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Demo
                      </button>
                    </a>
                    <a
                      href="https://github.com/MuhammeedNabil/Login-Application"
                      target={"_blank"}
                    >
                      <button
                        className={`btn btn-transpaent rounded-pill w-100 btn btn-bg-transparent text-white border bord bgHover textColor `}
                      >
                        View Code
                      </button>
                    </a>
                  </div>
                  <p className="card-text text-center mt-1">
                    <small className="text-muted">
                      Last updated on May 31
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
