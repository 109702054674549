/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styles from "./Footer.module.css";
import logo from "../Resources/Images/Logo2.png";
import { Icon } from '@iconify/react';

export default function Footer() {
  return (
    <section id="Footer" className={`${styles.cardBg} ${styles.bg1}`}>
    <div className="container">
      <div className="row">
        <div className='d-flex'>
        <div className={`col-md-6 ${styles.logo}`}>
          <a href="https://www.mohammednabil.online/" target={"_blank"}>
            <img className="w-50" src={logo} alt="" />
          </a>
        </div>

        <div className={`col-md-6 ${styles.icons}`}>
          <div className={`${styles.SOCIAL} mt-5 mb-3 fs-2 text-end`}>
            <a
              href="https://www.facebook.com/MoNbail19/"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className={`fa-brands ${styles.facebook} fa-facebook mx-3 text-white`}
              ></i>
            </a>
            <a
              href="https://github.com/MuhammeedNabil?tab=repositories"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className={`fa-brands ${styles.github} fa-github text-white`}
              ></i>
            </a>
            <a
              href="https://www.instagram.com/mohammed_nabil_elpop/"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className={`text-white fa-brands ${styles.instagram} fa-instagram mx-3 text-dark`}
              ></i>
            </a>
            <a
              href="https://www.linkedin.com/in/m-nabilbkr/"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className={`fa-brands ${styles.linkedin} fa-linkedin text-white  me-3`}
              ></i>
            </a>
            <a
              href="https://twitter.com/MohammeedNabil"
              target={"_blank"}
              rel="noreferrer"
            >
<i>
                <Icon icon="fa6-brands:x-twitter" className={`text-white mb-2 ${styles.twitter} text-dark`} />
                </i>
            </a>
          </div>
        </div>
        </div>
        {/* Main Row Close */}
      </div>
      {/* Container Close */}
    </div>
  </section>
  )
}
