/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styles from "./Skills.module.css";
import { Chip } from '@mui/material';
// import { Icon } from '@iconify/react';

export default function () {
    const skills = [
        'HTML5',
        'CSS3',
        'Bootstrap',
        'Tailwind',
        'Material-UI',
        'jQuery',
        'JavaScript (ES6+)',
        'Responsive Design',
        'React.js',
        'Nextjs',
        'Redux',
        'Redux toolkit',
        'RESTful APIs',
        'Git & GitHub',
        'Jest',
        'Testig React libarary',
        'Adobe Experience Manager [AEM]',
        // Add more skills as needed
      ];
  return (
    <>
      <section id="Skills" className={`${styles.bg1}`}>
        <div className="container py-5">
          <div className="row w-100 m-auto g-4">
            <strong
              className={`text-center text-white fs-1 bordHover border-bottom border-bottom-2 rounded-4 ${styles.bg1} mb-3`}
            >
              My Skills
            </strong>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {skills.map((skill, index) => (
          <Chip key={index} label={skill} variant="outlined" className={`btn btn-transparent border bord bgHover textColor rounded-pill text-white ${styles.btnHover}`} style={{ margin: '4px' }} />
        ))}
      </div>
            {/* Main Row Close */}
          </div>
          {/* Container Close */}
        </div>
      </section>
    </>
  );
}
