import React from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from "../Components/Home/Home";
import Navbar from "../Components/Navbar/Navbar";
import Notfound from "../Components/Notfound/Notfound";
import Education from '../Components/Education/Education';
import About from '../Components/About/About';
import Skills from '../Components/Skills/Skills';
import Projects from '../Components/Projects/Projects';
import WorkExperience from '../Components/WorkExperience/WorkExperience';
import Contact from '../Components/Contact/Contact';

export default function Pages() {
  return (
<>
<React.StrictMode>
    <BrowserRouter>

      <Navbar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='home' element={<Home />}></Route>
          <Route path='about' element={<About />}></Route>
          <Route path='education' element={<Education />}></Route>
          <Route path='skills' element={<Skills />}></Route>
          <Route path='projects' element={<Projects />}></Route>
          <Route path='Work-experience' element={<WorkExperience />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='*' element={<Notfound />}></Route>
        </Routes>

    </BrowserRouter>
  </React.StrictMode>
</>
  )
}
