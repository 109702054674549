/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Diamond from './Notfound.jpg'
import styles from './Notfound.module.css'


export default function Notfound() {
  const navigate = useNavigate();
  function handleSubmit(event) {
    event.preventDefault();

    navigate('/home');
  }
  return (
    <>
        <div className='m-auto position-relative mt-5'>
            <img src={Diamond} alt='Notfound' className={`${styles.imageWidth}`} />
            <button  onClick={handleSubmit} className={`bord rounded-pill bgHover ${styles.btnStyle}`}>Back Home Page</button>
            
        </div>
    </>
  )
}
