/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./Education.module.css";
import myPhoto from "../Resources/Images/education.jpg";
import routeCertifi from "../Resources/Images/Route certificate.JPG";
import gradCertifi from "../Resources/Images/Grad. Certificate.JPG";
import fwdCertifi from "../Resources/Images/FWD.JPG";
import NextjsCertfi from "../Resources/Images/Nextjs.png";
import CV from "../Resources/Certificates/Mohammed Nabil Resume.pdf";
import firstCertify from "../Resources/Certificates/Web development challenger track FWD.pdf";
import gradCertify from "../Resources/Certificates/Graduation Certificates.pdf";
import secoundCertify from "../Resources/Certificates/Route Front-End Web Development Diploma certificate.pdf";
import NextCertify from "../Resources/Certificates/certificate-of-completion-for-mastering-next-js-13-with-typescript.pdf";


export default function Education() {

const educationDetails = [
  {
    degree: "Engineering Certificate",
    Discription: "“Bachelor's in Chemical Engineering from Egyptian Academy for Engineering & Advanced Technology”",
    school: "Egyptian Academy for Engineering and Advanced Technology",
    year: "Graduated in 2022",
    certificateDownloadLink: gradCertify,
    certificateViewLink: gradCertifi,
  },
    {
      degree: "FWD Nano dgree certificate",
      school: "Udacity FWD",
      Discription: "“Challenger Track in HTML & CSS from FWD is a grant from the Ministry of Communications and it's cooperation with Udacity”",
      year: "Graduated in 2023",
      certificateDownloadLink:
      firstCertify,
      certificateViewLink: fwdCertifi,
    },
    {
      degree: "Frontend Development Diploma certificate",
      school: "Route Academy",
      Discription: "“FrontEnd Web Developer Certificate from Route Academy where I studied (HTML / CSS / Bootstrap / JS / jQuery / JS / React.js / Redux)”",
      year: "Graduated in 2023",
      certificateDownloadLink: secoundCertify,
      certificateViewLink: routeCertifi,
    },
    {
      degree: "Nextjs Mastering Diploma certificate",
      school: "Code With Mosh",
      Discription: "“Mastering in Nextjs Certificate from Code With Mosh website where I studied (build apps with Next.js 13+ and TypeScript / Style Next.js applications with Tailwind / Implement routing and navigation)”",
      year: "Graduated in 2024",
      certificateDownloadLink: NextCertify,
      certificateViewLink: NextjsCertfi,
    },
    // Add more education details as needed
  ];

  const handleCertificateDownload = (downloadLink, certificateName) => {
    fetch(downloadLink).then((response) => {
      response.blob().then((blob) => {
        const pdfURL = window.URL.createObjectURL(blob);
        const aLink = document.createElement("a");
        aLink.href = pdfURL;
        aLink.download = `${certificateName}.pdf`;
        aLink.click();
      });
    });
  };


  // Function to download cv on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(CV).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Mohammed Nabil Resume.pdf";
        alink.click();
      });
    });
  };

  return (
    <>
      <section id="Education" className={`${styles.bg2}`}>
        <div className="container py-5">
          <div className="row m-auto g-4 justify-content-center">
            <strong
              className={`text-center text-white fs-1 bordHover border-bottom border-bottom-2 rounded-4 ${styles.bg1} mb-3`}
            >
              My Education
            </strong>

            {/*---------------------Img & welcome card-----------------*/}
            <div className="col-md-12 g-1">
              <div className="row text-center align-items-center">
                <div className="col-md-6 mt-4 d-flex justify-content-center">
                  <img
                    className="w-75 rounded-5 border border-5 border-muted bordHover mb-3 "
                    src={myPhoto}
                    alt=""
                  />
                </div>
                {/*------------------------Card Body-----------------------*/}
                <div className="card-body m-auto offset-1 col-md-5">
                  <div className="row justify-content-center">
                    <p className="card-text">
                      Welcome to the educational part dedicated to me, where you
                      can view all the studies I have obtained and certificates,
                      and you can also download my CV. I hope you are happy to
                      be here on my website
                    </p>
                    {/*------------------CV & Certificates buttons-------------*/}
                    <div className="w-75 row g-2 justify-content-center">
                      <a
                        className="btn btn-bg-transparent text-white border bord bgHover col-md-10 d-flex  justify-content-center textColor "
                        target="_blank"
                        onClick={onButtonClick}
                      >
                        Download CV
                      </a>
                    </div>
                    {/*----------------------Last Update line------------------*/}
                    <p className="card-text mt-3 mb-5">
                      <small className="text-white-50">
                        Last updated on 7 of mar. 2023
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*-------------------My Education Cards-------------------*/}
            
            <div className="col-md-12 row g-4 m-auto">
  {educationDetails.map((edu, index) => (
    <div key={index} className="card text-center bg-transparent border bord rounded-3">
      <div className="row">
        <div className="col-md-4 align-items-center d-flex">
          <img src={edu.certificateViewLink} alt="" className="w-100" />
        </div>

        <div className="card-body col-md-8 d-flex flex-column justify-content-center align-items-center">
          <h5 className="card-title fw-bold textColor">{edu.degree}</h5>
          <p className="card-text">{edu.Discription}</p>
          <p className="card-text">
            {edu.school} - {edu.year}
          </p>
          <a
            target="_blank"
            className={`btn btn-transparent border bord bgHover textColor rounded-pill text-white ${styles.btnHover}`}
            onClick={() => handleCertificateDownload(edu.certificateDownloadLink, edu.degree)}
          >
            Download the Certificate
          </a>
        </div>
      </div>
    </div>
  ))}
</div>
          </div>
        </div>
      </section>
    </>
  );
}
