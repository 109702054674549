/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink } from 'react-bootstrap'
import styles from './Navbar.module.css'


export default function Navbar() {
  return (
    <>
      {/* ---------------------------------------------Top NavBar------------------------------------------- */}

      <nav className={`${styles.bg} navbar navbar-expand-lg fixed-top`}>
        <div className="container">
          <a  className={`navbar-brand ${styles.navbarBrand}`} href='https://www.mohammednabil.online/' target={'_blank'}>Mohammed Nabil</a>
          <button className={`navbar-toggler  bg`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className={`navbar-toggler-icon`}></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">

            {/* ------------------------------------------Right Nav Items-------------------------------------------*/}
            <ul id='myDIV' className="navbar-nav ms-auto mb-lg-0 textColor">
              {/*--------------------------------------- Download btn---------------------------------------------- */}
              <li className="nav-item ">
          <NavLink className={`nav-link textColor `} href="#About">About</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={`nav-link textColor`} href="#Education">Education</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={`nav-link textColor`} href="#Skills">Skills</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={`nav-link textColor`} href="#Projects">Projects</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={`nav-link textColor`} href="#WorkExperience">Work Experience</NavLink>
        </li>
                <li className="nav-item">
          <NavLink className={`nav-link textColor`} href="#Contact">Contact</NavLink>
        </li>
            </ul>

          </div>
        </div>
      </nav>
    </>
  )
}
